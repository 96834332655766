<template>
  <!-- start page title -->
  <div>
    
    <div class="container my-5">
      <div class="row align-items-center justify-content-center">
        <div class="col-11 col-lg-6 position-relative pe-0 mb-5 mb-lg-0 rounded">
          <a href="https://goo.gl/maps/SNwYW6DSDQ8EwcS78" title="Prince Court Medical Centre" target="_blank"><img src="@/assets/images/app/map2.png" class="position-relative img-fluid"></a> 
        </div>
        <div class="col-12 col-lg-6">
          <div class="px-3 px-lg-5">
            <img src="@/assets/images/logo-dark.png" height="70" :alt="title" class="mb-4 d-block">
            <div class="font-size-16 mb-3">{{$t('tel')}}: <a href="tel:+60127376208">(+60) 17 - 208 1101</a></div>
            <div>
              <strong class="fw-semibold text-primary font-style-2 mb-2 d-block font-size-20">Prince Court Medical Centre</strong>
              <p class="font-size-14">
              Room 15, Multidisciplinary Clinic, Level 2,
              39, Jalan Kia Peng,
              Kuala Lumpur 50450,
              Wilayah Persekutuan Kuala Lumpur,
              Malaysia.</p>

            </div>
            <div class="d-flex align-items-center font-size-22">
              <div class="mx-3"><a href="https://www.facebook.com/drlimsurgery" target="_blank"><i class="uil uil-facebook-f"></i></a></div>
              <div class="mx-3"><a href="https://www.instagram.com/drlimsurgery" target="_blank"><i class="uil uil-instagram"></i></a></div>
              <div class="mx-3"><a href="#" target="_blank"><i class="uil uil-youtube"></i></a></div>
              <div class="mx-3"><a href="http://wa.me/60172081101" target="_blank"><i class="uil uil-whatsapp"></i></a></div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
<script>
import appConfig from "@/app.config";
export default {
  components: {  },
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      title: appConfig.title,
      markers: [
        {
          title:"Prince Court Medical Centre",
          position: {
            lat: 3.148967,
            lng: 101.721733,
          },
        }
      ],
    }
  },
  mounted(){
  },
  created(){
    
  },
  methods:{
  },
  beforeDestroy() {
    
  }
};
</script>

