<script>
import appConfig from "@/app.config";
export default {
    
    props: {
      showFooterCert: {
        type: Boolean,
        default: true,
      }
    },
    data() {
        return {
            version: appConfig.version,
            name: appConfig.title
        }
    },
}
</script>

<template>
    <footer class="footer position-relative start-0 border-0 h-auto shadow-none font-size-15 bg-primary text-white">
    <div class="container-fluid text-center">
        <div class="row">
            <div class="col-12 text-white font-size-14">{{ new Date().getFullYear()}} © {{name}}</div>
        </div>
    </div>
    </footer>
</template>