<template>
  <!-- start page title -->
  <header>
    <div class="container drlim_header p-3">
      <div class="position-absolute top-0 end-0">
     
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-lg-auto">
          <div class="d-flex align-items-center"> 
            <router-link to="/" class="logo logo-dark me-auto">
            <img src="@/assets/images/logo-dark.png" height="50" :alt="title">
            </router-link>

            <div class="d-block d-lg-none me-2">
              <div class="input-group d-inline-block mb-0">
                <b-dropdown variant="light" right class="bg-white border shadow-none">
                  <template v-slot:button-content>
                    <!-- <img class="me-1" :src="selectedLang.flag" :alt="selectedLang.title" height="16" /> -->
                    {{ selectedLang.title }}
                  </template>
                  <b-dropdown-item
                    class="notify-item"
                    v-for="(value, i) in languages"
                    :key="`Lang${i}`"
                    :value="value"
                    @click="setLanguage(value)"
                  >
                    <!-- <img
                      :src="`${value.flag}`"
                      alt="user-image"
                      class="me-1"
                      height="12"
                    /> -->
                    <span class="align-middle"> {{ value.title }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div class="d-block d-lg-none">
            <b-dropdown variant="primary" right class="shadow-none">
                <template v-slot:button-content>
                <span class="mdi mdi-menu"></span>
                {{$t('menu')}}
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item :to="value.path" v-for="(value, index) in menu" :key="index">{{$t(value.title)}}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="col d-none d-lg-block">
            <b-nav fill class="custom-nav-drlim  align-items-center">
            <b-nav-item :to="value.path" v-for="(value, index) in menu" :key="index" >
            <span v-if="!value.subs.length" :class="{'link-active': ActiveButton(value)}">{{$t(value.title)}}</span>
            <b-nav-item-dropdown
                v-if="value.subs.length"
                :id="'my-nav-dropdown_'+index"
                toggle-class="nav-link-custom"
                right
              >
              <template v-slot:button-content>
                {{$t(value.title)}}
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <b-row class="px-2">
                <div class="column-count">
                  <b-col md="12" v-for="(x, y) in value.subs" :key="y">
                    <b-dropdown-item :to="`/services/${y}/${convertToSlug(x.title)}`" class="text-capitalize" target="">{{$t(x.title)}}</b-dropdown-item>
                  </b-col>
                </div>
            </b-row>
            </b-nav-item-dropdown>
            </b-nav-item>
            <b-nav-item >
              <div class="input-group d-inline-block mb-0">
                <b-dropdown variant="light" right class="bg-white border shadow-none lang">
                  <template v-slot:button-content>
                    <!-- <img class="me-1" :src="selectedLang.flag" :alt="selectedLang.title" height="16" /> -->
                    {{ selectedLang.title }}
                  </template>
                  <b-dropdown-item
                    class="notify-item"
                    v-for="(value, i) in languages"
                    :key="`Lang${i}`"
                    :value="value"
                    @click="setLanguage(value)"
                  >
                    <!-- <img
                      :src="`${value.flag}`"
                      alt="user-image"
                      class="me-1"
                      height="12"
                    /> -->
                    <span class="align-middle"> {{ value.title }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </div>
    <Common ref="commonFunc"/>
  </header>
</template>
<script>
import Common from "@/components/common";
import appConfig from "@/app.config";
export default {
  components: { Common },
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      selectedLang: {},
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/china.png"),
          language: "zh",
          title: "中文",
        },
      ],
      title: appConfig.title,
      menu:[
        {
          title:"home",
          path:"/home",
          subs:[]
        },
        {
          title:"about_us",
          path:"/about-us",
          subs:[]
        },
        {
          title:"services",
          path:"/services",
          subs:[
          {
              title: "rectal_polyps_and_cancers",
              path: "/"
            },
            {
              title: "colon_polyps_and_cancers",
              path: "/"
            },
            {
              title: "roboti_surgery",
              path: "/"
            },
            {
              title: "endoscopy",
              path: "/"
            },
            {
              title: "haemorrhoid_surgery",
              path: "/"
            },
            {
              title: "anal_fistula",
              path: "/"
            },
            {
              title: "anal_fissure",
              path: "/"
            },
            {
              title: "laser_surgery",
              path: "/"
            },
            {
              title: "rectal_prolapse",
              path: "/"
            },
            {
              title: "creation_and_closure_of_stoma",
              path: "/"
            },
            {
              title: "peritoneal_cancer",
              path: "/"
            },
            {
              title: "general_surgery",
              path: "/"
            }
          ]
        },
        {
          title:"articles",
          path:"/articles",
          subs:[]
        },
        {
          title:"get_care",
          path:"/get-care",
          subs:[]
        },
      ]
    }
  },
  mounted(){
    if (this.$refs.commonFunc.getSetLang() ==undefined){
      this.$refs.commonFunc.setLang('en')
    }
    const Language = this.languages.find(lang => lang.language === this.$i18n.locale);
    this.selectedLang = Language
  },
  created(){
    
  },
  methods:{
    setLanguage(value) {
      this.selectedLang = value
      this.$refs.commonFunc.setLang(value.language)
      this.$refs.commonFunc.getSetLang()
    },
    convertToSlug(str) {
      // Replace all spaces_with_underscores
      str = str.replace(/\s+/g, '-').replace(/_/g, '-').toLowerCase();
      return str;
    },
    ActiveButton(value){
      if (value.path == '/home'){
        if (this.$route.path == '/'){
          return true;
        }
        else if (this.$route.path == '/home'){
          return true;
        }
      }else{
        if (this.$route.path == value.path){
          return true;
        }
      }
      
      
    }
  }
};
</script>
<style>
body{
  overflow-x: hidden;
}
.column-count{column-count: 2;}
.custom-nav-drlim .nav-link.router-link-active .nav-link-custom{color:#C92323;}
.custom-nav-drlim .nav-link{ padding: 0px; font-weight: 500; text-transform: uppercase;}
.custom-nav-drlim .nav-link:hover, .link-active{color:#C92323;}
.custom-nav-drlim .dropdown-menu{width: 550px !important; margin-top: 15px; text-transform: none;}
.custom-nav-drlim .lang .dropdown-menu{width: auto !important; }
</style>