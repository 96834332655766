<script>
import { layoutComputed } from "@/state/helpers";

//import Sidebar from "@/components/side-bar";
//import RightSidebar from "@/components/right-sidebar";
import Footer from "@/components/footer";
export default {
  data() {
    return {
      PageTitle:""
    }
  },
  components: {
    //Sidebar,
    //RightSidebar,
    Footer,
  },
   computed: {
    ...layoutComputed,
    
  },
  name: "vertical",
  created() {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
  },
  methods: {
    
  },
  
};
</script>

<template>
  <!-- Begin page -->
  <div id="layout-wrapper">
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
      <div class="page-content p-0">
        <div class="w-100">
          <slot />
        </div>
      </div>
      <!-- End Page-content -->
      <Footer />
    </div>
    <!-- end main content-->
  </div>
</template>
